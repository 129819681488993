import jQuery from 'jquery';


jQuery(function($) {
  $(".activity-list").each(function() {
    var list=$(this).find(">ul");
    var next=$(this).find("a.next");
    var prev=$(this).find("a.prev");
    prev.click(function(e) {
      e.preventDefault();
      var width=list.find(">li").innerWidth()+20;
      list.animate({scrollLeft: list.scrollLeft()-width}, 300);
    });
    next.click(function(e) {
      e.preventDefault();
      var width=list.find(">li").innerWidth()+20;
      list.animate({scrollLeft: list.scrollLeft()+width}, 300);
    });
  });
  
  
  $(".new-doc").click(function(e) {
    e.preventDefault();
    $(".form-popup").addClass("visible");
  });
  $(".form-popup .close").click(function(e) {
    e.preventDefault();
    $(".form-popup").removeClass("visible");
  });
});
