import jQuery from 'jquery';

jQuery(function($) {
  
  $("ul.accordion").each(function() {
    var list=$(this);
    var items=list.find(">li");
    var heads=list.find(">li>.head");
    heads.click(function() {
      var index=heads.index(this);
      if (items.eq(index).hasClass("open")) {
        items.eq(index).find(">.content").css("display", "block");
        items.eq(index).find(">.content").slideUp(300);
        setTimeout(function() {
          items.eq(index).removeClass("open");
        }, 30);
      } else {
        items.filter(".open").find(">.content").css("display", "block");
        items.filter(".open").find(">.content").slideUp(300);
        items.eq(index).find(">.content").css("display", "none");
        items.eq(index).find(">.content").slideDown(300);
        setTimeout(function() {
          items.filter(".open").removeClass("open");
          items.eq(index).addClass("open");
        }, 16);
      }
    });
  });
  
  $(".home-sear .hs-box").each(function() {
    var $this=$(this);
    $this.find(">input").on("input", function(e) {
      if (this.value.length>=3)
        $this.addClass("qs-active");
      else
        $this.removeClass("qs-active");
    });
  });
  
});
