import jQuery from 'jquery';

jQuery(function($) {
  
  var validate=function(requirements, value) {
    var valid=true;
    var reqs=(requirements || "").split(" ");
    for(var req of reqs) {
      if (!validate[req])
        continue;
      if (!valid)
        break;
      if (!validate[req](value))
        valid=false;
    }
    return valid;
  }
  validate.required=function(value) {
    if (value===null || value===undefined)
      return false;
    return !!(value+"");
  }
  validate.email=function(value) {
    value=value+"";
    return !!(value.match(/^.+@.+\..+$/));
  }
  
  $("form[data-validate]").each(function() {
    $(this).on("submit", function(e) {
      var valid=true;
      $(this).find("[data-required]").each(function() {
        var validField=validate($(this).attr("data-required"), $(this).val());
        if ($(this).attr("type")=="checkbox")
          validField=$(this).prop("checked");
        if (!validField) {
          if (valid) {
            valid=false;
            $(this).focus();
          }
          $(this).parent().parent().addClass("error");
        } else {
          $(this).parent().parent().removeClass("error");
        }
      }).one("change", function(e) {
        $(this).parent().parent().removeClass("error");
      });
      if (!valid)
        e.preventDefault();
    });
  });
});

jQuery(function($) {
  $(".select").each(function() {
    var el=$(this);
    var span=$(this).find(">span");
    el.find(">select").on("change", function() {
      var value=$(this).val();
      el.find(">select>option").each(function() {
        if ($(this).attr("value")==value)
          value=$(this).html().trim();
      });
      span.html(value);
    }).trigger("change");
  });
  
  $("input[type=file]").each(function() {
    var el=$(this);
    var container=el.parent();
    var label=container.find(">span");
    var button=container.find(">a");
    el.change(function() {
      var fileName=el.val() || "";
      label.html(fileName.replace(/^.*\\/, ""));
    });
  });
  $(".show-pw").click(function(e) {
    e.preventDefault();
    var el=$(this);
    var input=el.parent().find("input[type=password],input[type=text]");
    if (input.attr("type")=="password")
      input.attr("type", "text");
    else
      input.attr("type", "password");
    input.focus();
  });
});

