import jQuery from 'jquery';

if ('pushState' in window.history) {
  
  var goToDynamicPage=function(url, popped) {
    jQuery.get(url, function(data) {
      var html=jQuery(data);
      var isChild=html.find(".clist.dynamic").hasClass("child");
      if (isChild) {
        var parentList=html.find(".clist.dynamic>ul:last-child");
        jQuery(".clist.dynamic>ul:last-child").html(parentList.html())
        var parentContent=html.find(".child-content");
        jQuery(".child-content").html(parentContent.html());

        jQuery(".clist.dynamic").addClass("child");
        jQuery(".page-content.dynamic").addClass("child");
      } else {
        var parentList=html.find(".clist.dynamic>ul:first-child");
        jQuery(".clist.dynamic>ul:first-child").html(parentList.html())
        var parentContent=html.find(".parent-content");
        jQuery(".parent-content").html(parentContent.html());

        jQuery(".clist.dynamic").removeClass("child");
        jQuery(".page-content.dynamic").removeClass("child");
      }
      
      jQuery(".breadcrumbs ul").html(html.filter(".breadcrumbs").find("ul").html());
      
      var title=data.match(/<title>([^<]+)/, data);
      if (title)
        title=title[1];
      document.title=title;
      
      if (!popped)
        window.history.pushState(null, title, url);
    }, "html");
  }
  
  jQuery(document).on("click", ".clist.dynamic .back a", function(e) {
    e.preventDefault();
    var href=jQuery(this).attr("href");
    goToDynamicPage(href);
  });
  
  jQuery(document).on("click", ".cl-link", function(e) {
    e.preventDefault();
    var href=jQuery(this).attr("href");
    goToDynamicPage(href);    
  });
  
  jQuery(window).on("popstate", function(e) {
    goToDynamicPage(window.document.location.href, true);
  });
  
}

jQuery(document).on("keyup", ".qsear input", function(e) {
  var list=jQuery(this).closest("form").parent().find(">ul");
  var value=jQuery(this).val();
  list.find(">li").each(function() {
    if (!value || jQuery(this).html().toLowerCase().indexOf(value.toLowerCase())!=-1) {
      jQuery(this).slideDown(300);
    } else {
      jQuery(this).slideUp(300);
    }
  });
});
