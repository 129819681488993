import jQuery from 'jquery';

// home carousel
jQuery(function($) {
  $(".head-carousel").each(function() {
    var items=$(this).find(".hc-items>li");
    var hcNav=$(this).find(".hc-nav>li");
    var hcLinks=$(this).find(".hc-nav>li>a");
    var currentItem=0;
    var updateItemPos=function() {
      items.each(function(index) {
        $(this).css("left", (index-currentItem)*100+"%");
      }).removeClass("active").eq(currentItem).addClass("active");
      hcNav.removeClass("active").eq(currentItem).addClass("active");
    }
    updateItemPos();
    hcLinks.click(function(e) {
      e.preventDefault();
      currentItem=hcLinks.index(this);
      updateItemPos();
    });
  });
});


// counting
jQuery(function($) {
  var items=$("[data-count]");
  var $window=$(window);
  $window.on("scroll.counter", function() {
    var winTop=$window.scrollTop();
    var winHeight=$window.innerHeight();
    items.each(function() {
      var $this=$(this);
      if ($this.data("rendered"))
        return;
      var itemTop=$this.offset().top;
      if (itemTop<winTop+winHeight*0.9) {
        $this.data("rendered", true);
        var currentCount=0;
        var countTo=$this.attr("data-count")*1;
        var nextFrame=function() {
          currentCount=Math.ceil(currentCount+(countTo-currentCount)*0.3);
          $this.html(currentCount);
          if (currentCount<countTo)
            setTimeout(nextFrame, 100);
        };
        setTimeout(nextFrame, 200);
      }
    });
  }).trigger("scroll.counter");
  
});
