import 'focus-within-polyfill';

import jQuery from 'jquery';

window.jQuery=jQuery;

import './common';
import './validate';
import './home';
import './listnav';
import './content';
import './news';
import './myegov';
