import jQuery from 'jquery';

// clicks on common elements
jQuery(function($) {
  $(".lang .ll-active a").click(function(e) {
    e.preventDefault();
    $(this).closest(".lang").toggleClass("open");
  });
  $(".my-egov>a").click(function(e) {
    e.preventDefault();
    $(this).closest(".my-egov").toggleClass("open");
  });
  $(".foot-top a").click(function(e) {
    e.preventDefault();
    $("html,body").animate({scrollTop: 0}, 300);
  });
  $(".set-handle").click(function(e) {
    e.preventDefault();
    $(this).closest("header").toggleClass("setopen");
  });
});

// theme switcher
jQuery(function($) {
  $(".settings-box .font a").click(function(e) {
    e.preventDefault();
    var index=$(".settings-box .font a").index(this);
    $(".settings-box .font li").removeClass("active").eq(index).addClass("active");
    $("html").removeClass("large small");
    if (index==0)
      $("html").addClass("small");
    if (index==2)
      $("html").addClass("large");
  });
});

// scroll animations
jQuery(function($) {
  var $window=$(window);
  var footTop=$(".foot-top");
  var header=$("header");
  $window.on("scroll.common", function(e) {
    var scrollTop=$window.scrollTop();
    if (scrollTop>=40)
      footTop.css("bottom", "0px");
    else
      footTop.css("bottom", scrollTop-40+"px");
    if (scrollTop>=110)
      header.addClass("scrolled");
    else
      header.removeClass("scrolled");
  }).trigger("scroll.common");
});
